// https://router.vuejs.org/zh/
import 'nprogress/nprogress.css'

import multiPrint from '@/views/multiPrint/index.vue'
import multiPrintDetail from '@/views/multiPrint/detail.vue'
import orderDetail from '@/views/multiPrint/orderDetail.vue'
// 定义路由，每个路由都需要映射到一个组件
const routes = [
  {
    path: '/multiPrint',
    name: 'multiPrint',
    component: multiPrint,
    meta: {
      needGuard: true,
      title: '称毛重管理',
    },
    
  },
  {
    path: '/multiPrintDetail',
    name: 'multiPrintDetail',
    meta: {
      title: "称毛重"
    },
    component: multiPrintDetail,
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      title: "批次详情"
    },
    component: orderDetail,
  },
]

export function mergeRoutes(allRoutes: any[]) {
  allRoutes.push(...routes)
}
