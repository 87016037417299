<script lang="tsx" setup>
import { getCurrentInstance } from 'vue'
const instance = getCurrentInstance();
import { normalizeStatus, getURLParameters } from '@/utils/utils'
import * as myOrderApi from '@/api/my_order'
import * as configApi from '@/api/config'
import { imgSrc } from "@/utils/utils"
import { useStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'
const store = useStore();
import { useTokenStore } from '@/stores/token'
import { showFailToast } from 'vant';
const tokenStore = useTokenStore();
const router = useRouter();
const route = useRoute()
const userInfo = ref(null)
const refreshing = ref(false)
const finished = ref(false)
const loading = ref(false)
const error = ref(false)
const list = ref([])
let page = 1
const size = 6

const { isWechat } = tokenStore;
const brandId = ref();

onMounted(() => {
  userInfo.value = tokenStore.userInfo;
  brandId.value = instance.proxy.$brandId;
  getBrand(brandId.value);
})

function onLoad() {
  if (refreshing.value) {
    list.value = []
    page = 1
    refreshing.value = false
  }
  getPage();
}

// 获取页面信息
const getPage = () => {
  myOrderApi.page({
    page,
    brand_id: brandId.value || "",
    size,
  }).then((res: any) => {
    const data = res || []
    list.value = list.value.concat(normalizeStatus(data))
    if (data.length < size)
      finished.value = true
    page++
  }).catch(() => {
    error.value = true
  }).finally(() => {
    loading.value = false
  })
}

// 获取品牌信息
const getBrand = (val) => {
  configApi.brand(val).then((res: any) => {
    store.setBrandInfo(res); // 存储品牌信息
    setIcon(res)
  }).catch((err) => {
    console.log("configErr", err)
  }).finally(() => {
  })
}

const setIcon = (res) => {
  const favicon = res.favicon
  if (favicon) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);
  }
}

const onRefresh = () => {
  // 清空列表数据
  finished.value = false
  // 重新加载数据
  loading.value = true
  onLoad()
}

// 跳转到3D相册
const toAlbum = (data) => {

  store.updateOrderInfo(data)
  if (data.has_experience || data.status >= 4100) {
    // 高斯跳转到web
    router.push({
      path: data.is_gsplat == 1 ? '/photo3d/gsplat' : '/photo3d',
    })
  }else {
    showFailToast('3D相册制作中'.$t)
    return
  }
  console.log("toAlbum", data)
  
  // const u = wx.getStorageSync('userInfo');
  //   var pid = e.currentTarget.dataset.pid;
  //   var cn = e.currentTarget.dataset.cn;
  //   var oid = e.currentTarget.dataset.oid;
  //   var cid = e.currentTarget.dataset.cid;
  //   var status = e.currentTarget.dataset.status;
  //   var remainingExperienceStatus = e.currentTarget.dataset.remain_status;
  //   var expireTime = e.currentTarget.dataset.expire_time;
  //   var digitalOrderId = e.currentTarget.dataset.digital_order_id;
  //   var digitalType = e.currentTarget.dataset.digital_type;
  //   var createTime = e.currentTarget.dataset.create_time;
  //   var titleCode = e.currentTarget.dataset.title_code;
  //   console.log(e.currentTarget.dataset)
  //   //判断createTime时间是否在2023-04-15 00:00:00 之后
  //   wx.request({
  //       url: config.url + '/miniProgram/judgeP3dLogOrderTime',
  //       data: {photoTime: createTime, pid},
  //       header: {'content-type': 'application/json'},
  //       success: (res) => {
  //           if (res.data.data.use_status != 1) {
  //               wx.showToast({
  //                   title: '该订单不支持3D相册，需重新拍照',
  //                   icon: 'none',
  //                   duration: 2000
  //               })
  //               return
  //           }

  //           const url = `/package3D/pages/3d/3d?openid=${u.openid}`
  //               + `&unionid=${u.unionid}&id=${pid}&oid=${oid}`
  //               + `&cn=${cn}&cid=${cid}&expireTime=${expireTime}`
  //               + `&digitalType=${digitalType}`
  //               + `&status=${status}`
  //               + `&titleCode=${titleCode}`
  //               + `&remainingExperienceStatus=${remainingExperienceStatus}`

  //           console.log("url", url);

  //           if (digitalOrderId == 0) {
  //               wx.navigateTo({
  //                   url: url,
  //               });
  //               return
  //           }

  //           if (status < 4100) {
  //               wx.showToast({
  //                   title: '3D相册制作中',
  //                   icon: 'none',
  //                   duration: 2000
  //               })
  //               return
  //           }


  //           //获取时间戳
  //           // const timestamp = Date.parse(new Date());
  //           // const url = `http://172.16.20.5:3000/?t=${Date.now()}#/model?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}&expireTime=${expireTime}&remainingExperienceStatus=${remainingExperienceStatus}`
  //           // const url = `https://wechat.suwa3d.com/3d/index.html?t=${Date.now()}#/model?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}`
  //           // const navtitle = '3D相册'; // 这个标题是你自己可以设置的
  //           wx.navigateTo({
  //               // 跳转到webview页面
  //               // url: `/pages/webview/webview?nav=${navtitle}&url=${encodeURIComponent(url)}`,
  //               url: url,
  //           });
  //       },
  //       fail: (err) => {
  //           console.log(err);
  //       }
  //   })
}

function getExpeienceText(item: any) {
  switch (item.remaining_experience?.title_code) {
    case 1:
      return '3D相册体验中'.$t
    case 2:
      return '3D相册体验结束'.$t
    case 3:
      return '您购买的3D相册正在精修中！'.$t
  }
}

function showExperience(item: any) {
  return item.remaining_experience?.status !== 0
}

const detailClick = (data: any) => {
  store.updateOrderInfo(data)
  router.push({
    path: '/myOrder/detail',
  })
}

</script>

<template>
  <van-nav-bar v-if="!isWechat()" :title="store.navTitle" safe-area-inset-top />
  <div class="page">
    <div class="container">
      <div class="box-wrap">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <Brand />
          <van-list v-model:loading="loading" v-model:error="error" :error-text="'请求失败，点击重新加载'.$t"
            :finished-text="'没有更多了'.$t" :finished="finished" @load="onLoad">
            <van-card v-for="item in list" :key="item.pid">
              <template #title>
                <van-cell :title="item.pid" is-link :value="'详情'.$t" class="title" @click="detailClick(item)" />
              </template>
              <template #thumb>
                <img v-if="item.texture_cover_img" :src="imgSrc(item.texture_cover_img)" alt="" @click="toAlbum(item)">
                <div v-else class="empty_img" @click="toAlbum(item)" ></div>
              </template>
              <template #tags>
                {{ item.head_count }}{{ '人'.$t }}/{{ item.model_json.$t }}
              </template>
              <template #footer>
                <div class="van-wrap">
                  <div class="van-status">
                    <div :class="['status', item.statusColor == 0 ? 'undone' : '']">{{ item.statusName.$t }}</div>
                    <div class="created_time">{{ item.update_time }}</div>
                  </div>
                  <div class="btns">
                    <div class="check_btn" v-if="item.status >= 4100 || item.has_experience" @click="toAlbum(item)">{{ '查看3D相册'.$t }}</div>
                    <div class="check_btn disabled" v-else>{{ '查看3D相册'.$t }}</div>
                  </div>
                </div>
                <div :class="['photograph-box', { expire: item.remaining_experience?.status === 2 }]"
                  v-if="showExperience(item)">
                  <div class="box-wrap">
                    <div class="experience">{{ getExpeienceText(item) }}</div>
                    <div class="right" v-if="item.remaining_experience.day > 0">
                      <div class="countdown">{{ '倒计时'.$t }}<div class="bold">{{ item.remaining_experience?.day }}</div>
                        {{ '天'.$t }}</div>
                    </div>
                  </div>
                </div>
              </template>
            </van-card>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <Tabbar></Tabbar>
  </div>
</template>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  // padding-bottom: calc(env(safe-area-inset-bottom) + 96px);
  background-color: #F3F3F3;
}

.container {
  display: block;
  padding: 5px 15px 0 15px;

  >.box-wrap {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 100px;
    // height: calc(100vh - 96px);
  }
}

.text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  z-index: 9;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  background-color: #1aad19;
  color: #fff;
  border-radius: 5px;
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
}

:deep(.van-card) {
  background: #ffffff;
  margin-top: 10px;
  border: 3px solid #F0F2F5;
  border-radius: 8px;
  padding: 0;

  .van-card__header {
    display: flex;
    justify-content: space-between;
    width: 315px;
    height: 90px;
    flex-direction: row;
    margin: 15px auto;
  }

  .van-card__thumb {
    width: 120px;
    height: 90px;
    border-radius: 16px;
    margin-right: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .empty_img {
    width: 120px;
    height: 90px;
    background: #fff;
  }

  .van-card__content {
    display: flex;
    flex: unset;
    flex-direction: column;
    justify-content: space-between;
    width: 180px;
    height: 90px;
    overflow-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #666666;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
  }

}

:deep(.van-cell__title) {
  flex: unset;
  overflow-wrap: break-word;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #333333;
  font-size: 17px;
  font-family: Source Han Sans CN-Bold;
  font-weight: 700;
  white-space: nowrap;
}

:deep(.van-cell__value),
:deep(.van-icon) {
  // width: 56px;
  color: #767676;
  font-size: 14px;
  font-weight: 400;
}

:deep(.van-cell) {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 180px;
  height: 32px;
}

:deep(.van-card__footer) .van-cell {
  margin-top: 0.4rem;
  background: #FAFAFA;
}

:deep(.van-card__footer) .van-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 315px;
  margin: 0 auto;

  .van-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 315px;
    height: 46px;
    background-color: #FAFAFA;
    margin: 0 auto;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;

    .status {
      color: #009944;
      font-size: 14px;
    }

    .created_time {
      color: #AAAAAA;
      font-size: 14px;
    }

    .undone {
      color: #E6A23C;
    }
  }
}

:deep(.van-card__footer) .van-cell__title {
  text-align: left;
  color: #009944;
}

.btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 34px;
  margin-bottom: 10px;

  .check_btn {
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #009944;
    border: 1px solid#009944;
    padding: 0 12.5px;
    box-sizing: border-box;
  }

  .disabled {
    opacity: .3;
  }
}

.photograph-box {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  background: #FCF5EB;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: .5px solid #FDE2E2;
  box-sizing: border-box;

  .box-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 315px;
    height: 100%;
    margin: 0 auto;

    .experience {
      font-size: 15px;
      color: #E6A23C;
      font-weight: 700;
      font-family: Source Han Sans CN-Bold;
    }

    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #E6A23C;
      position: relative;
      font-family: Source Han Sans CN-Regular;

      .countdown,
      .hint {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;

        .bold {
          font-weight: 700;
        }
      }

      .buy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 106px;
        height: 17px;
        background: #E6A23C;
        font-weight: 400;
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 5px;
      }
    }
  }

}

.expire {
  border: .5px solid #FDE2E2;
  background: #FDF0F0;

  .box-wrap {
    .experience {
      color: #F56C6C;
    }

    .right {
      color: #F56C6C;

      .buy-btn {
        background: #F56C6C;
      }
    }
  }
}
</style>
