<template>
  <div class="page">
    <div class="page_wrap">
      <div class="content">
        <van-cell-group inset border>
          <van-cell title="批次号" :value="id" />
          <van-cell title="机器编号" :value="machine_id" />
          <van-cell title="模型数量" :value="model_count" />
            <van-cell title="批次详情" :value="order_count" is-link :to="{ name: 'orderDetail', query: { id: id } }" />
        </van-cell-group>

        <van-cell-group style="margin-top: 10px;" inset border>
          <van-cell class="diy_cell" title="模型称重照片" label="需将模型和重量均拍入照片">
            <van-uploader style="margin-top: 10px;" v-model="fileList" :after-read="beforeUpload"  />
          </van-cell>
          <van-cell class="diy_cell" title="模型总重(克)">
            <van-field placeholder="请输入" v-model="total_weight">
              <template #button>g</template>
            </van-field>
          </van-cell>
        </van-cell-group>
        <div class="confirm-btn" @click="submitData">确认</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
const route = useRoute();
const router = useRouter()
import { showSuccessToast, showToast } from "vant";
const id = route.query.id as string | number;
const machine_id = route.query.machine_id as string | number;
const model_count = route.query.model_count as string | number;
const order_count = route.query.order_count as string | number;
import * as api from '@/api/multiPrint'
const fileList = ref([
]);

const total_weight = ref('')
const imgList = ref()

// 图片添加时间水印
function addWatermarkToImage(file, timestamp) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = function(e) {
            img.onload = function() {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const width = img.width;
                const height = img.height;

                canvas.width = width;
                canvas.height = height;

                // 将原始图片绘制到 Canvas 上
                ctx.drawImage(img, 0, 0, width, height);

                // 添加水印文本
                ctx.font = '80px Arial';
                ctx.fillStyle = '#ff0000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';           // 垂直方向顶部对齐

                // 添加水印文本
                ctx.fillText(timestamp, width / 2, 100);      // 在指定位置绘制文字

                // 获取带水印的新图片数据
                canvas.toBlob((blob) => {
                  // 创建新的文件
                  const newFile = new File([blob], file.name, {
                    type: file.type,
                  });
                  resolve(newFile);
                }, file.type);  // 使用原始文件类型
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    });
}

const beforeUpload = async () => {
 try {
  let file = fileList.value[0].file;
  console.log("beforeUpload",fileList)
  var timestamp = "时间:" + dayjs().format("YYYY-MM-DD HH:mm:ss");
  let data = {
    keyName: file.name,
  }
  let fileData = await addWatermarkToImage(file, timestamp)
  console.log("addWatermarkToImage", fileData)
  api.getPutSignURL(data).then(res => {
    imgList.value = res.signedURL
    api.uploadToOss(res.signedURL, fileData)
  }).catch(err=>{
    showToast('提交失败' + err.message)
  })
 }catch(err) {
 }
}

const submitData = () => {
  console.log("submitData",imgList.value)
  if(imgList.value == '' && total_weight.value == '') {
    return
  }

  let params = {
    id: id,
    model_weight_photo: imgList.value,
    total_weight: total_weight.value
  }
  api.add({...params}).then((res: any) => {
    showSuccessToast('提交成功, 请稍后在检查')
    router.back()
  }).catch((err: Error) => {
    showToast('提交失败' + err.message)
  })
}

</script>

<style scoped>
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;
  padding-top: 10px;
  .page_wrap {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0px auto;
    .content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .diy_cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .confirm-btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #4281E3;
        color: #FFFFFF;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
}
:deep(.van-cell-group--inset) {
  margin: 0;
}
:deep(.van-cell::after){
  transform: scaleY(1.5);
}
</style>