// https://router.vuejs.org/zh/
import 'nprogress/nprogress.css'

import myOrder from '@/views/my_order/index.vue'
import OrderDetail from '@/views/my_order/detail.vue'
// 定义路由，每个路由都需要映射到一个组件
const routes = [
  {
    path: '/myOrder',
    name: 'myOrder',
    component: myOrder,
    meta: {
      needGuard: true,
      title: '我的订单',
    },
    
  },
  {
    path: '/myOrder/detail',
    name: 'detail',
    meta: {
      needGuard: true,
      title: "订单详情"
    },
    component: OrderDetail,
  }
]

export function mergeRoutes(allRoutes: any[]) {
  allRoutes.push(...routes)
}
