const ko = {
  user: {
      "language": "language"
  },
  "store_name": "문점",
  "type_id_2": "모델크기",
  "type_id_3": "촬영포즈",
  "type_id_4": "애완동물",
  "type_id_5": "추가 휴대품",
  "total": "합계",
  "total_price": "가격",
  "submit_tip": "로그인 클릭 후 제출하여 대기합니다",
  "mobile_form_tip": "휴대폰 번호와 이름 등록",
  "title": "사진을 찍고 줄을 서다",
  "err_tip_page": "링크 매개변수 오류로 인해 매장 정보를 가져올 수 없습니다",
  "pl_chose_goods": "먼저 피규어 크기를 선택하세요",
  "pl_chose_attr": "먼저 촬영 포즈를 선택하세요",
  "success_queue": "대기 성공",
  "fail_queue": "대기 실패",
  "email": "이메일",
  "mobile": "휴대폰",
  "InternationalTelephoneAreaCode": "국제 전화 국가 코드",
  "name": "이름",
  "submit": "제출",
  "mobile_empty": "휴대폰 번호는 비워둘 수 없습니다",
  "name_empty": "이름은 비워둘 수 없습니다",
  "email_empty": "사서함은 비워둘 수 없습니다",
  "tip": "1 인용 사진 참고가격이라 자세히 점원과 문의할 수 있습니다",
  "user_service_agreement": "서비스 계약",
  "user_privacy_agreement": "개인 정보 보호 정책",
  "suwa_child_privacy_policy": "어린이 개인 정보 보호 정책",
  "agree_and_continue": "동의하고 계속",
  "agree_text": "나는 동의한다",
  "privacy_hint_one": "데이터가 처리되고 저장되는 위치에 관계없이, 당사는 항상 개인 정보 보호 정책 및 사용자 계약에 따라 귀하의 데이터를 수집하고 처리합니다.",
  "privacy_hint_two": "중국 본토의 개인 정보 보호법의 요구 사항을 준수하기 위해, 귀하는 이 동의서에 서명해야합니다.",
  "agreement_and_policy": "사용자 계약 및 개인 정보 보호 정책",
  "agree_tip": "계약서를 읽고 동의해 주세요",
  "your_queue_number_is": "대기 일련 번호는 다음과 같습니다",
  "Please_wait_for_the_number_to_be_called": "전화 번호를 기다려 주세요",
  "Number": "번호",
  "go_back": "돌아오다",
  "user_phone_is_empty": "핸드폰 번호는 비워둘 수 없습니다",
  "store_is_empty": "문점은 비워둘 수 없다",
  "calculate_total_amount_error": "총 금액 계산 오류",
  "create_user_error": "사용자 생성 오류",
  "You_have_registered_please_wait": "이미 등록하셨으니 기다려 주세요",
  "server_error": "서버가 소실되었으니, 잠시 후에 다시 시도해 주십시오",
  "name_required": "이름은 필수 항목입니다",
  "mobile_required": "휴대폰은 필수다",
  "站姿": "자세",
  "宠物+站姿": "애완동물+자세",
  "弯腰/半蹲/坐椅": "허리를 굽히다/쭈그리고 앉다",
  "全蹲/坐地": "쭈그리고 앉다",
  "宠物": "애완동물",
  "道具": "소품",
  "suwa_user_service_agreement": "빠른 와우 사용자 서비스 계약",
  "suwa_privacy_policy": "빠른 와우 개인 정보 보호 정책",
  "queue_buy_type": "구매 유형",
  'loading': '로드 중',
  '加载中...': '로드 중...',
  "agree_tip_prefix": "나는 이미 읽고 동의했다",
  "queue_price_tip": "1 인용 사진 참고가격이라 자세히 점원과 문의할 수 있습니다",
  "birthday": "생일",
  "手办": "숫자",
  "3D相册": "3D 앨범",
  "体验3D相册": "체험 3D 앨범",
  "元": "위안",
  "合计：": "합계:",
  "您前面还有": "당신 앞에 아직",
  "人请等待叫号": "명이 있습니다. 호출을 기다려 주세요",
  "返回": "돌아오다",
  "排队成功": "줄을 서서 성공하다",
  "3D摄影": "3D 촬영",
  "排队": "줄을 서다",
  "登录": "로그인",
  "客户手机号和客户编号不能同时为空": "고객 휴대폰 번호와 고객 번호는 동시에 비워 둘 수 없습니다",
  "详情": "상세 정보",
  "人": "사람",
  "3D相册制作中": "3D 앨범 제작 중",
  "3D相册体验中": "3D 앨범 체험 중",
  "3D相册体验结束": "3D 앨범 체험 끝",
  "您购买的3D相册正在精修中！": "구매하신 3D 앨범은 지금 정밀 수리 중입니다!",
  "请求失败，点击重新加载": "요청이 실패했습니다. 다시 로드를 클릭하십시오",
  "没有更多了": "더 이상",   
  "查看3D相册": "더 이상",
  "倒计时": "카운트다운",
  "天": "하늘",
  "建模完成":"모델링이 완료되었습니다",
  "待修模":"수정준비",
  "修模中":"수정",
  "待打印":"인쇄준비",
  "待建模":"모델링준비",
  "打印中":"프린터",
  "未提交":"제출되지 않았습니다",
  "快递中":"특급 배송",
  "称净重":"무게측정(g)",
  "建模中":"모델링",
  "3D相册已创建":"3D 앨범 생성",
  "后道工序处理":"인쇄 처리",
  "后道工序处理中":"후속 프로세스 처리 중",
  "订单信息": "주문 정보",
  "订单详情": "주문 상세 정보",
  "订单ID":"주문 ID",
  "拍摄时间":"촬영 시간",
  "复制":"복사",
  "重打":"다시 입력",
  "加打":"인쇄 추가",
  "订单":"주문",
  "首页":"홈 페이지",
  "我的订单":"주문",
  "订单记录": "주문 기록",
  "联系门店": "문점에 연락하다",
  "拍摄门店": "매장을 찍다",
  "费用合计": "비용 합계",
  "复制成功": "복사에 성공했습니다",
  "复制失败": "복사에 실패했습니다",
  "保存成功": "저장에 성공했습니다",
  "暂无快递信息": "잠시 택배 정보가 없습니다",
  "退出登录...": "로그인을 종료하다...",

  "渲染中...": "렌더링 중...",
  "数据加载中...": "데이터 로드 중...",
  "故障模型，请联系门店处理": "고장 모델, 문점에 연락하여 처리해 주세요",
  "3D相册体验已结束": "3D 앨범 체험이 끝났습니다",
  "您购买的3D相册正在精修中，请稍候！": "구입하신 3D 앨범은 지금 정밀 수리 중입니다. 잠시만 기다려 주세요!",
  "您未购买3D相册": "3D 앨범을 구입하지 않았습니다",
  "体验还剩": "체험이 아직 남아있다",
  "模型问题反馈": "모델 문제 피드백",
  "我要认领": "나는 주장하고 싶다.",
  "纯色背景": "단색 배경",
  "已选择": "선택됨",
  "主题背景": "테마 배경",

  "复位": "재설정",
  "保存": "저장",
  "旋转": "회전",
  "停止": "멈추다",
  "换背景": "배경",
  "收起": "거두다",
  "展开": "펼치다",
  "体验AR": "AR",
  "我的":"윤곽",
  "关于我们":"우리에 대해서",
  "语言":"언어",
  "头像":"아바타",
  "账号":"계좌번호",
  "性别":"성별",
  "退出登录":"로그인을 종료하다",
  "确认退出登录？":"로그인 종료를 확인하시겠습니까?",
  "确认退出":"확인",
  "我再想想":"취소",
  "个人中心":"개인센터",
  "请登录":"로그인하십시오",
  "删除订单":"주문을 삭제합니다",
  "取消":"확인",
  "称毛重管理":"무게 측정 관리",
  "称毛重":"무게 측정",


  "退出AR":"수출",
  "简体中文":"중국어 간체",
  "英文":"영어",
  "繁体中文":"중국어 번체",
  "韩文":"한국어",
}
export default ko