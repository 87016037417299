import request from '@/utils/request'

// 参数为 page, size, pid
export const page = (params: any) => {
  return request('/printBatchOrder/page', { params, method: 'GET' })
}

// 批次详情
export const detailPage = (data: any) => {
  return request('/printBatchOrder/detailPage', { data, method: 'POST' })
}

// 主要参数为pid
export const add = (params: any) => {
  return request('/printBatchOrder/grossWeight', { params, method: 'POST' })
}

// 签名
export const getPutSignURL = (data: any) => {
  return request('/printBatchOrder/putSignURL', { data, method: 'POST' })
}

// oss
export const uploadToOss = (putSignUrl: any, file: any) => {
  return request({ 
  url: putSignUrl,
  headers: {
    "Content-Type": "application/octet-stream"
  },
  data: file, method: 'PUT', withCredentials: false, })
}

