<template>
  <div>
    <div class="page">
      <div class="page_wrap">
        <van-search
          v-model="keyWord"
          background="#F3F3F3"
          shape="round"
          clearable
          @search="onSearch"
          @clear="getPage"
          input-align="center"
          placeholder="机器编号"
        />
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model:loading="loading" v-model:error="error" :error-text="'请求失败，点击重新加载'" :finished-text="'没有更多了'"
              :finished="finished" @load="onLoad"
            >
              <div class="content">
                <div class="content_wrap">
                  <div class="content_item" v-for="item, index in list" :key="index">
                    <div class="left">
                      <div class="content_item_title">机器编号{{item.machine_id}}
                  <van-tag style="margin-left: 10px;" :type="getProgressStatusType(item.process_status)">
                      {{ renderProgressStatus(item) }}
                    </van-tag>
                </div>
                      <div class="content_item_info">
                        <div class="time">提交打印时间: <span>{{ item.print_time }}</span></div>
                        <div class="count">模型数量: <span>{{ item.model_count }}</span></div>
                        <div class="reason" v-if="showReason(item)">原因: <span>
                      {{item.message}}
                  </span></div>
                </div>
                    </div>
                    <div class="right">
                      <div class="icon_item" @click="goDetail(item)">
                        <img class="icon" :src="weight" alt="">
                        <div class="icon_text">称毛重</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as api from '@/api/multiPrint'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter();
import weight from '@/assets/common/weight.png';
const keyWord = ref('')
const loading = ref(false)
const error = ref(false)
const finished = ref(false)
const refreshing = ref(false)
let currentPage = ref(1)
let currentSize = ref(20)

const list = ref([])
const onLoad = () => {
  if (refreshing.value) {
    currentPage.value = 1;
    list.value = [];
    refreshing.value = false;
  }

  getPage()
};

const onRefresh = () => {
  // 清空列表数据
  finished.value = false;

  loading.value = true;
  onLoad();
};

const onSearch = () => {
  currentPage.value = 1;
  list.value = [];
  getPage()
}

// 查看详情
const goDetail = (val: any) => {
  console.log("goDetail",val)

  router.push({
    path: '/multiPrintDetail',
    query: {
      id: val.id,
      machine_id: val.machine_id,
      model_count: val.model_count,
      order_count: val.order_count,
    }
  })
}

function getPage() {
  api.page({ 
      page: currentPage.value,
      keyWord: keyWord.value, 
      size: currentSize.value
    }).then(res => {
    console.log("getPage",res)
    let data = res.list || [];
    list.value = list.value.concat(data)
    if(data.length < currentSize.value) {
      finished.value = true;
    }
    currentPage.value++
    loading.value = false
  }).catch(() => {
    error.value = true
    loading.value = false
  })
}

function renderProgressStatus(item: any) {
  switch (item.process_status) {
    case 0:
      return '待处理'
    case 1:
      return `处理中(${item.process_count}/${item.model_count})`
    case 2:
      return '处理完成'
    default:
      return '处理失败'
  }
}

function getProgressStatusType(progressStatus) {
  switch (progressStatus) {
    case 0:
      return 'default'
    case 1:
      return 'warning'
    case 2:
      return 'success'
    case 3:
      return 'danger'
    default:
      return 'danger'
  }
}

function showReason(item) {
  return item.process_status == 3;
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;
  padding-top: 10px;
  .page_wrap {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0px auto;
    .content {
      position: relative;
      width: 100%;
      height: 93vh;
      margin: 10px 0;
      .content_wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        .content_item {
          position: relative;
          width: 100%;
          // min-height: 100px;
          display: flex;
          justify-content: space-between;
          background-color: #FFFFFF;
          border-radius: 10px;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 10px;
          .left {
            position: relative;
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .content_item_title {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }
            .content_item_info {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              .time {
                font-size: 14px;
                color: #666666;
              }
              .count {
                font-size: 14px;
                color: #666666;
                span {
                  font-weight: bold;
                  color: #000;
                }
              }
              .reason {
                font-size: 14px;
                color: #ec4444;
              }
            }
          }
          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 25%;
         
            .icon_item {
              width: 60px;
              height: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .icon {
                width: 20px;
                height: 20px;
              }
              .icon_text {
                font-size: 12px;
                color: #1296db;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
:deep(.van-search) {
  padding: 0;
  background: #ffffff!important;
  border-radius: 30px;
}
:deep(.van-search__content) {
  background: #ffffff!important;
}
.van-pull-refresh { 
  height: calc(95vh) !important; /* 计算高度(减去的高度自己调) */
 	overflow: auto !important;  /* 滚动自适应 */
} 
 
.van-list { 
    height: auto !important; /* 列表高度自适应 */
}
</style>