import request from '@/utils/request'

export const calc = (data: any) => {
  return request('queue/calc', {
    method: 'POST',
    data,
  })
}

export const queueAdd = (data: any) => {
  return request('queue/addNew', {
    method: 'POST',
    data,
  })
}
// export const queueAdd = (data: any) => {
//   return request('queue/add', {
//     method: 'POST',
//     data,
//   })
// }

export const attr = (params: any) => {
  return request('queue/attr', {
    method: 'GET',
    params,
  })
}
