<script setup lang="ts">
import { getCurrentInstance } from 'vue'
const instance = getCurrentInstance();  
import { contractMap } from '@/dict/status_dict'
import dayjs from 'dayjs'
import { showDialog, showFailToast } from 'vant'
import { useRoute } from 'vue-router'
import * as queueApi from '@/api/queue'
import * as configApi from '@/api/config'
import router from '@/router'
import {getTreeData} from "@/utils/utils"
// import {AttrResponse} from './utils/type'
// import { AxiosResponse } from 'axios'
import { useStore } from '@/stores'
const store = useStore();
const isProd = process.env.mode === 'production'
const hintShow = ref(false)
const isAll = ref(true)
const loading = ref(false)
const choseManyType = ref([2, 4, 5])
const name = ref(isProd ? '' : '测试')
const mobile = ref(isProd ? '' : '13695004676')
const birthday = ref(isProd ? '' : '1990-10-10')
const attrBuyTypes = ref([])
const buyTypes = ref([])
const attrList = ref([])
const goodsList = ref([])
const choseList = ref({})
const choseAttrsIds = ref([])
const choseGoodsIds = ref([])
const totalPrice = ref(0)
const goodsTotalPrice = ref(0)
const shopId = ref(0)
const brandId = ref()
const logo = ref('')
const currency = ref('')
const useLogo = ref(false)
const v = ref('')
const shopName = ref('')
// const checkboxRefs = ref([])
const agree = ref(false)
const showPicker = ref(false)
const showAgree = ref(false)
const agreePage = ref('')
const agreeTitle = ref('')
const current = ref();
const route = useRoute()
const cate_map = ref({
	1: '数字模型',
	2: '手办尺寸',
	3: '额外携带',
	4: '',
})
const minDate = new Date(1900, 0, 1)
const maxDate = new Date()
const ignore = ref()
const currentDate = ref(['1990', '10', '10'])

// const toggle = (index) => {
//   checkboxRefs.value[index].toggle();
// };
onMounted(() => {
  shopId.value = parseInt(String(route.query.shop_id), 10)
  brandId.value = parseInt(String(route.query.shop_id), 10)
  ignore.value = parseInt(String(route.query.ignore), 10)
  shopName.value = String(route.query.shop_name)
  currency.value = String(route.query.currency)
  useLogo.value = Boolean(route.query.use_logo)
  v.value = String(route.query.v)
  logo.value = String(route.query.logo)
  if (!logo.value) {
  // 主要给定制版客户他们需要展示logo
    logo.value = `/resource/img/logo/${shopId.value}.png?t=${new Date().getTime()}`
  }
  brandId.value = instance.proxy.$brandId;
  isShowHint();
  getBrand(brandId.value);
  // init()
})
const isShowHint = ()=> {
  isAll.value = ignore.value == 0 ? false : true;
  if(ignore.value == 0) {
    hintShow.value = false
  }else {
    hintShow.value = true
  }
}


const closeHint = () => {
  hintShow.value = false;
}

const checkChange = () => {
  agree.value = true;
}

// 获取品牌信息
const getBrand = (val) => {
  configApi.brand(val).then((res: any) => {
    store.setBrandInfo(res); // 存储品牌信息
  
    setIcon(res)
    }).catch((err) => {
      console.log("configErr", err)
    }).finally(() => {
  })
} 

function onViewAgree(name: string, val: number) {
  let url;
  let sid = brandId.value;
  let timeStamp = Date.now();
  // TODO: 英文路径在/v1/en下面，之后要新增多语言支持时需要根据语言来切换协议
  // 版本号之后采用后端回传来确认协议版本号
  if(store.brandInfo.agrees) {
    store.brandInfo.agrees.filter((el: any)=> {
      if(el.agree_type == val) {
        url = el.url
        agreeTitle.value = el.title
      }
    })
  }else {
    if(sid == 130 || sid == 134) {
      url = `/pages/agreement/v1/cn/${sid}/${name}.html?` + timeStamp
    }else {
      url = `/pages/agreement/v1/${name}.html?` + timeStamp;
    }
  }
  agreeTitle.value = contractMap[val]
  showAgree.value = true
  agreePage.value = url
}

const setIcon = (res) => {
  const favicon = res.favicon
  if (favicon) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');  
    link.type = 'image/x-icon';  
    link.rel = 'icon';  
    link.href = favicon;
    document.head.appendChild(link);
  }
}

const hidden = ref(false)
const init = () => {
  nextTick(()=> {
    loading.value = true
    queueApi.attr({
      shop_id: shopId.value,
      brand_id: brandId.value || "",
    }).then((res: any) => {
        goodsList.value = res.goods;
        hidden.value = !!res.hidden;
        // setAttrList(res.attrs);
        // setGoodsList(res.goods);
        // 购买类型默认无效
        attrBuyTypes.value = res.attrBuyTypes || [];
        if (attrBuyTypes.value.length) buyTypes.value = [attrBuyTypes[0]?.value.id];
				
        // 初始化
        defaultSelected();
    })
      .catch((error) => {
        console.log('err', error)
        showDialog({ message: error.message }).then(() => {
          router.replace('/')
        })
      }).finally(() => {
        loading.value = false
      })
  })
}

const selectedGoodsMap = ref({})
const selectedGoodsAttrMap = ref({})
const attributeMap = ref({})

const setGoodsList = (data) => {
	let _data = data || [];
	let list = [];
	let cateMap = {};
	_data.forEach((el) => {
		let cate = cateMap[el.cate_id];
		if (!cate) {
			cate = {
				id: el.cate_id,
				cate_name: el.cate_name,
				children: [],
				attrs: []
			};
			cateMap[el.cate_id] = cate;
			list.push(cate);
			selectedGoodsMap.value[el.cate_id] = [];
			selectedGoodsAttrMap.value[el.cate_id] = [];
			if (el.attr_ids != "") {
				JSON.parse(el.attr_ids).forEach((el) => {
					let c = attributeMap.value[el];
					if (c) {
						cate.attrs.push(c);
					}
				});
			}
		}
		cate.children.push(el);
		cate.children.sort((a, b) => a.sort - b.sort);
	});

	list.sort((a, b) => {
		return a.sort - b.sort;
	});
	attrList.value = list;
}
const selectedData = ref({})
const attributeList = ref([])
const setAttrList = (data) => {
	let _data = data || [];
	_data.map((e) => {
		e.parentId = e.parent_id;
		e.orderNum = e.id;
		attributeMap.value[e.id] = e;
	});

	let __data = getTreeData(_data);
	__data.forEach((e) => {
		selectedData.value[e.id] = [];
	});

	attributeList.value = __data;
}

const selectBuyType = (buyType) => {
	buyTypes.value = buyType;
	getGoodsPrice();
}
const cate_map_list = ref({
	1: [],
	2: [],
	3: [],
	4: []
})
const currentGood = ref(null)
const selectGoods = (cateId, goodsId) => {
	// 当前选择的分类
	current.value = cateId;
  currentGood.value = goodsId;
}

const goodsChange = (e) => {
	
  // 将选择的分类数据插入到总数据
  // if (index >= 0) {
  //     cate_map_list.value[currentData]?.splice(index, 1)
  //   } else {
  //     cate_map_list.value[currentData]?.push(goodData);
  //   }
	
	getGoodsPrice();
}

const selectGoodsAttr = (cateId, attrId) => {
	selectedGoodsAttrMap.value[cateId] = attrId;
	getGoodsPrice();
}
const calculated = ref(false)

watch(() => totalPrice.value, (val, preVal) => {
	totalPrice.value = calculated.value ? totalPrice.value : 0
},{immediate: true})

// 计算价格
function getGoodsPrice() {
  // 遍历选中的产品
  const attrIds = []
  const goodsIds = []
  Object.values(selectedGoodsMap.value).forEach((cl: any)=> {
		Object.values(cl).forEach((item)=> {
			goodsIds.push(item)
		})
	})
	Object.values(selectedGoodsAttrMap.value).forEach((dl: any) => {
    if(!Array.isArray(dl)) {
      attrIds.push(dl)
    }
		
	});
  choseAttrsIds.value = attrIds
  choseGoodsIds.value = goodsIds
  queueApi.calc({
    buy_types: filterBuyTypes(),
    attr_ids: attrIds,
    goods_ids: goodsIds,
    shop_id: shopId.value,
  })
    .then((res: any) => {
      console.log('获取到价格', res)
      totalPrice.value = res.totalPrice * 100 || 0
      goodsTotalPrice.value = res.goodsTotalPrice * 100 || 0
      calculated.value = true
    })
    .catch((err) => {
      console.log('err', err)
      totalPrice.value = 0
      goodsTotalPrice.value = 0
      calculated.value = false
    })
}

function filterBuyTypes() {
  return (buyTypes.value || []).filter((item) => item > 0)
}

// const onGoodsChange = (data) => {
//     totalPrice.value = data.total_amount;
//     goodsTotalPrice.value = data.total_goods_amount;
//     choseAttrsIds.value = data.attr_ids
//     choseGoodsIds.value = data.goods_ids
//     calculated.value = data.calculated;
// }

// 提交订单
function onSubmit() {
  loading.value = true
  // if (totalPrice.value === 0) {
  //   if (choseGoodsIds.value.length === 0) {
  //     showDialog({ message: '请先选择手办尺寸'.$t })
  //     loading.value = false
  //     return
  //   }
  //   if (choseAttrsIds.value.length === 0) {
  //     showDialog({ message: '请先选择拍摄姿势'.$t })
  //     loading.value = false
  //     return
  //   }
  //   showDialog({ message: '请先选择手办尺寸'.$t })
  //   loading.value = false
  //   return
  // }

  if (agree.value === false && isAll.value) {
    hintShow.value = true
    loading.value = false
    return
  }

  submitQueue().finally(() => {
    loading.value = false
  })
}

function submitQueue() {
  const params = {
    // attr_ids: choseAttrsIds.value,
    // goods_ids: choseGoodsIds.value,
    shop_id: shopId.value,
    applyTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    customer_mobile: mobile.value,
    customer_name: name.value,
    // buy_types: filterBuyTypes(),
  }

  return queueApi.queueAdd(params)
    .then((res: any) => {
      // showDialog({ message: '排队成功' })
      // const path = `/queue/succeed?shop_id=${shopId.value}&shop_name=${shopName.value}&currency=${currency.value}&use_logo=${useLogo.value ? 1 : ''
      //   }&v=${v.value}&index=${res.index}`
      const path = `/queue/succeed?shop_id=${shopId.value}&shop_name=${shopName.value}&use_logo=${useLogo.value ? 1 : ''
        }&v=${v.value}&index=${res.index}`
      router.push(path)
    })
    .catch((err: Error) => {
      showDialog({ message: err.message })
    })
}
const goodsHidden = () => {
	return hidden.value;
	// return this.shop_id == 86;
}
	// 默认选中
const defaultSelected = () => {

  if(!goodsHidden()) {
		return;
	}
	attrList.value.forEach((el) => {
		if (el.id == 2) {
			el.children.forEach(child => {
				if (child.goods_name == '18cm') {
					selectGoods(el.id, child.id)
				}
			})
		}
		
		if (el.attrs.length) {
			el.attrs.forEach(child => {
				if (child.attr_name == '站姿') {
					selectGoodsAttr(el.id, child.id)
				}
			})
		}
		// console.log(JSON.stringify(el))
	})
}


function onConfirm({ selectedValues }) {
  currentDate.value = selectedValues
  birthday.value = selectedValues.join('-')
  showPicker.value = false
}
</script>

<template>
  <div class="page" :class="[{'goods-hidden': goodsHidden()}]">
    <Hint :hintShow="hintShow" @onViewAgree="onViewAgree" @check="checkChange" @close="closeHint"></Hint>
    <van-form @submit="onSubmit">
    <img v-if="useLogo" :src="logo" class="logo">
    <van-cell-group inset>
      <van-field
        v-model="shopName" disabled readonly name="shopName" label-width="65px" :label="'门店'.$t"
      />
      <van-field
        v-model="name" name="name" :label="'姓名'.$t" label-width="65px" :placeholder="'姓名'.$t"
        :rules="[{ required: true, message: '请填写姓名'.$t }]"
      />
      <van-field
        v-model="mobile" name="mobile" :label="'手机'.$t" label-width="65px" :placeholder="'手机'.$t"
        :rules="[{ required: true, message: '请填写手机'.$t }]"
      />
      <van-field
        v-model="birthday" is-link readonly name="birthday" label-width="65px" :label="'生日'.$t" :placeholder="'生日'.$t"
        :rules="[{ required: true, message: '请填写生日'.$t }]" @click="showPicker = true"
      />
      
      <!-- <van-field :label="'购买类型'.$t" label-width="65px" v-if="attrBuyTypes.length > 0">
				<template #input>
					<el-checkbox-group v-model="buyTypes" size="small" @change="selectBuyType">
						<el-checkbox-button v-for="al in attrBuyTypes" :key="al.id" :label="al.id">
							{{ al.name.$t }}
						</el-checkbox-button>
					</el-checkbox-group>
				</template>
			</van-field> -->
      <!-- <template v-for="item in attrList">
			<template
				v-if="item.children && item.children.length && item.children.length > 0"
			>		
				<van-field :label="cate_map[item.id].$t" label-width="65px">
					<template #input>
						<el-checkbox-group v-model="selectedGoodsMap[item.id]" size="small" @change="goodsChange">
							<el-checkbox-button v-for="cl in item.children" :key="cl.id" :label="cl.id" @click="selectGoods(item.id, cl.id)">
							 	{{ item.id == 1 ? '选购'.$t : cl.goods_name.$t }}
							</el-checkbox-button>
						</el-checkbox-group>
					</template>
				</van-field>
        <template v-if="item.attrs.length">
          <van-field :label="'拍摄姿势'.$t" label-width="65px">
            <template #input>
                <el-radio-group v-model="selectedGoodsAttrMap[item.id]" size="small">
                  <el-radio-button v-for="rl in item.attrs" :key="rl.id" :label="rl.id" @click="selectGoodsAttr(item.id, rl.id)" >
                    {{ rl.attr_name.$t }}
                  </el-radio-button>
                </el-radio-group>
            </template>
          </van-field>
        </template>
      </template>
      </template> -->
      <!-- <van-field>
        <template #input>
          <div class="warning-box">
            <img class="image" src="@/assets/common/warning.png" alt="" />
            <div class="tip">{{'以上为单人拍照参考价格，详细可与店员咨询'.$t}}</div>
        </div>
        </template>
      </van-field> -->
  </van-cell-group>

    <div class="empty-footer" />
    <!-- <van-submit-bar
      v-if="!loading" :loading="loading" :currency="currency.$t" :price="totalPrice" :button-text="'提交'.$t"
      :label="'合计：'.$t"
      @submit="onSubmit"
    > -->
    <van-submit-bar
      v-if="!loading" :loading="loading" :button-text="'提交'.$t"
      @submit="onSubmit"
    >
      <!-- <van-checkbox v-model="checked">全选</van-checkbox> -->
      <template #tip v-if="isAll">
        <van-checkbox  icon-size="18px" v-model="agree" checked-color="#07c160">
          <!-- {{'我已阅读并同意'.$t}} -->
          <a 
            href="javascript:;" class="agreement"
            @click.stop="onViewAgree('user_service', 1)"
          >《{{'用户服务协议'.$t}}》</a>
          <a
            href="javascript:;" class="agreement"
            @click.stop="onViewAgree('user_privacy', 2)"
          >《{{'用户隐私政策'.$t}}》</a>
          <a v-if="store.brandInfo.agrees?.length > 2"
            href="javascript:;" class="agreement"
            @click.stop="onViewAgree('child_privacy', 3)"
          >《{{'儿童隐私政策'.$t}}》</a>
        </van-checkbox>
      </template>
    </van-submit-bar>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-date-picker
        v-model="currentDate" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </van-form>

  <van-overlay :show="loading">
    <div class="loading-wrapper" @click.stop>
      <van-loading size="24px">
        {{'加载中'.$t}}...
      </van-loading>
    </div>
  </van-overlay>

  <van-dialog v-model:show="showAgree" :title="agreeTitle.$t" :confirm-button-text="'我已知晓'.$t" width="100vw" class="agree-dialog">
    <iframe :src="agreePage" class="agree-iframe" />
  </van-dialog>
  </div>
  
</template>

<style lang="less" scoped>
:deep(.el-checkbox-button) {
  --el-checkbox-button-checked-bg-color: #1aad19;
  --el-checkbox-button-checked-border-color: #1aad19;
  --el-checkbox-button-checked-color: #1aad19;
}
:deep(.el-radio-button) {
  --el-radio-button-checked-bg-color: #1aad19;
  --el-radio-button-checked-border-color: #1aad19;
}
:deep(.el-checkbox-button__inner:hover) {
  color: #000;
}
:deep(.el-radio-button__inner:hover) {
  color: #fff;
}

:deep(.van-submit-bar__button--danger) {
    background: #1aad19;
}
.goods-hidden{
		display: none;
	}
.page {
    background-color: #f5f5f5;
    height: 100vh;
    position: relative;
    padding-top: 15px;

}
.logo {
  width: 96%;
  margin: 0.5rem auto;
  /* min-height:10rem; */
  text-align: center;
}

.empty-footer {
  height: 10rem
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.agree-iframe {
  height: 80vh;
  width: 100vw;
}

.warning-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .image {
        margin-right: 5px;
        width: 12px;
        height: 12px;
    }

    .tip {
        font-size: 12px;
        color: #666666;
    }

}
.agreement {
  color: #1aad19;
}
</style>
