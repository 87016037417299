<template>
  <div ref="container"></div>
</template>
<script lang="ts" setup>
import {ref} from "vue"
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {ConvexHull} from 'three/examples/jsm/math/ConvexHull.js'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader} from "three/examples/jsm/loaders/RGBELoader"
import { localStorage } from "@/utils/local-storage";
// 导入lil.gui
import {GUI} from "three/examples/jsm/libs/lil-gui.module.min.js"
const props = defineProps({
modelFile: {type: String, default: () => "",},
bgModel: {type: String, default: ""},
pid: {type: Number, default: 0},
});
import { showSuccessToast, showToast } from "vant";
const container = ref(null)
const STATE = {
  NONE: -1,
  MOVE: 0,
  ZOOM_OR_PAN: 1,
  POSITION_MOVE: 1,
}
const mouseInfo = ref({startX: 0, startY: 0, isDown: false, startPointerDistance: 0, state: STATE.NONE})

let model, scene, camera, renderer, controls;
const preventStop = ref(false)
const rotateTimer = ref(null)
const autoRotate = ref(null)
const twoFirst = ref(true)
const lastPoint = ref(
{
  touchStartX1: 0,
  touchStartY1: 0,
  touchStartX2: 0,
  touchStartY2: 0,
}
)
const emit = defineEmits([
"changeBackgound",
"rotateControl",
"handleProgress",
"handleLoaded",
]);

// 监听组件销毁事件
onBeforeUnmount(() => {
// 销毁 Three.js 模型
scene.remove(model);
clearTimer();
model.traverse((child) => {
  if (child.isMesh) {
    child.geometry.dispose();
    child.material.dispose();
  }
});

// 清理渲染器
renderer.dispose();
});

const initData = () => {
let storage = localStorage.get("positionData");
console.log("在模型加载之前", storage)
let pid = props.pid;
let obj= {
  pid: pid,
  scale: {
      x: modelContainer.scale.x,
      y: modelContainer.scale.y,
      z: modelContainer.scale.z,
  },
  initScale: {
      x: modelContainer.scale.x,
      y: modelContainer.scale.y,
      z: modelContainer.scale.z,
  },
  position: {
      x: modelContainer.position.x,
      y: modelContainer.position.y,
      z: modelContainer.position.z,
  },
  rotation: {
      x: modelContainer.rotation.x,
      y: modelContainer.rotation.y,
      z: modelContainer.rotation.z,
  },
}
if(!storage || storage.length == 0) {
  localStorage.set("positionData", [obj])
}else {
    let hasFoundData = false;
    storage.filter((item, i) => {
      // 只更新
      if (item.pid == pid) {
          hasFoundData = true;
          modelContainer.rotation.x = item.rotation.x
          modelContainer.rotation.y = item.rotation.y
          modelContainer.rotation.z = item.rotation.z
          modelContainer.scale.x = item.scale.x
          modelContainer.scale.y = item.scale.y
          modelContainer.scale.z = item.scale.z
          modelContainer.position.x = item.position.x
          modelContainer.position.y = item.position.y
          modelContainer.position.z = item.position.z
          // this.gltfItemLights.position.x = item.lightPosition.x
          // this.gltfItemLights.position.y = item.lightPosition.y
          // this.gltfItemLights.position.z = item.lightPosition.z
          // this.gltfItemLights.rotation.x = item.lightRotate.x
          // this.gltfItemLights.rotation.y = item.lightRotate.y
          // this.gltfItemLights.rotation.z = item.lightRotate.z
      }
  });

  if (!hasFoundData) {
      storage.push(obj);
      localStorage.set("positionData", storage)
  }
}
// 加载模型
loadModel(props.modelFile)
}

watch(() => props.modelFile, (val, oldVal) => {
if (val) {
  initData()
  // setTimer()
  console.log("modelFile", props.modelFile)
}
}, {
immediate: true
})
const modelContainer = new THREE.Object3D();
const loader = new GLTFLoader();
const loadModel = (modelPath) => {
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('./draco/')
// 创建场景
scene = new THREE.Scene();
// const {width, height} = scene
// 创建一个包含模型的父对象

// 旋转缩放相关配置
// 创建相机
camera = new THREE.OrthographicCamera(
  window.innerWidth / -10.5,
  window.innerWidth / 10.5,
  window.innerHeight / 10.5,
  window.innerHeight / - 10.5,
  0.1,
  1000
  // 90,
  // window.innerWidth / window.innerHeight,
  // .1,
  // 1000
);

// 加载 glb 格式的 3D 模型
loader.setDRACOLoader(dracoLoader)
loader.load(
  // "src/assets/model/scene.gltf",
  modelPath,
  (gltf) => {
    // 加载成功后的回调函数
    emit("handleLoaded")
    model = gltf.scene;
    // model.position.set(0, 0, 0);
    const box = new THREE.Box3().setFromObject(model);
    // let sizeBox = new THREE.Box3()
    // sizeBox.expandByObject(gltf.scene)
    // const v3 = new THREE.Vector3()
    // sizeBox.getSize(v3)
    
    // 获取包围盒中心点
    const center = box.getCenter(new THREE.Vector3());
    model.position.sub(center); // 将模型位置移到原点处
    // 将模型添加到父对象中
    modelContainer.add(model);
    // modelGeometry.center(); // 这个等同于上面这行代码
    // model.translateX(-80)
    scene.add(modelContainer);
    
  },
  (xhr) => {
    // 加载过程中的回调函数
    emit("handleProgress",Math.floor((xhr.loaded / xhr.total) * 100))
  },
  (error) => {
    // 加载失败的回调函数
    console.error("Failed to load model", error);
  }
);

// 添加环境光
const ambientLight = new THREE.AmbientLight(0xffffff, 2);
scene.add(ambientLight);

// 从上方照射的白色平行光，强度为 0.5。
const directionalLight = new THREE.DirectionalLight( 0xffffff, 2 );
// directionalLight.castShadow = true;
directionalLight.position.x = 1;
directionalLight.position.y = 1;
directionalLight.position.z = 80;
console.log("directionalLight",directionalLight)
directionalLight.target = modelContainer;
scene.add( directionalLight );

// 创建渲染器
renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0x000000, 0);
renderer.setPixelRatio(window.devicePixelRatio); // 设置画布分辨率 提高画质
container.value.appendChild(renderer.domElement);

// 加载hdr贴图
// let rgbeLoader = new RGBELoader();
// const rgbUrl = props.bgModel || "src/assets/bg.hdr";
// rgbeLoader.load(rgbUrl, envMap => {
//     // 设置球形贴图
//     envMap.mapping = THREE.EquirectangularReflectionMapping;
//     // 设置环境贴图
//     scene.background = envMap;

//     scene.environment = envMap;

// })

// 创建点光源
var pointLight = new THREE.PointLight(0xffffff, 500); // 设置点光源的颜色和强度
pointLight.position.set(0, 0, 100); // 设置点光源的位置
scene.add(pointLight);

// 相机位置
camera.position.z = 200;
camera.position.y = 0;
camera.position.x = 0;
camera.lookAt(0, 0, 0)
// 坐标轴
// const axesHelper = new THREE.AxesHelper(5)
// scene.add( axesHelper );

// // 添加轨道控制器
// controls = new OrbitControls(camera, renderer.domElement);
// controls.autoRotate = true;
// controls.autoRotateSpeed = 5;

// 监听缩放\旋转操作
renderer.domElement.addEventListener('touchstart', (event) => {
console.log("touchstart", event)
handleTouchStart(event)
});

const handleTouchStart = (event) => {
mouseInfo.value.isDown = true
// 双指
const touch0 = event.touches[0]
const touch1 = event.touches[1]
// 单点触控
if (event.touches.length === 1) {
    mouseInfo.value.startX = touch0.pageX
    mouseInfo.value.startY = touch0.pageY
    mouseInfo.value.state = STATE.MOVE // 这边换成rorate会比较好点
} else if (event.touches.length === 2) {
    // 双指，计算两指距离
    // 一般是平移或者缩放
    const dx = (touch0.pageX - touch1.pageX)
    const dy = (touch0.pageY - touch1.pageY)
  
    mouseInfo.value.startPointerDistance = Math.sqrt(dx * dx + dy * dy)
    mouseInfo.value.startX = (touch0.pageX + touch1.pageX) / 2
    mouseInfo.value.startY = (touch0.pageY + touch1.pageY) / 2
    mouseInfo.value.state = STATE.ZOOM_OR_PAN
}
}

renderer.domElement.addEventListener('touchmove', (event) => {
handleTouchMove(event)
});

// 开始触碰
const handleTouchMove = (event) => {
if (!mouseInfo.value.isDown) {
    return
}
switch (mouseInfo.value.state) {
    case STATE.MOVE:
        if (event.touches.length === 1) {
            handleRotate(event)
        } else if (event.touches.length === 2) {
            // 兼容处理，如果是移动过程中出现双指时，需要取消单指，然后再重新计算
            renderer.domElement.removeEventListener("touchmove",handleTouchMove, false)
            renderer.domElement.removeEventListener("touchend",handleTouchEnd, false)
            handleTouchStart(event)
        }
        break
    case STATE.ZOOM_OR_PAN:
        if (event.touches.length === 1) {
        } else if (event.touches.length === 2) {
            handleZoomOrPan(event)
        }
        break
    default:
        break
}
}

let rotateSpeed = 0.010;
// 模型旋转
const handleRotate = (event) => {
// 当自动旋转的时候 短暂停止
if (autoRotate.value && !preventStop.value) {
    clearTimer();
    // 防抖
    preventStop.value = true
    setTimeout(() => {
        setTimer();
        preventStop.value = false
    }, 2000);
}
const x = event.touches[0].pageX
const y = event.touches[0].pageY
const {startX, startY} = mouseInfo.value
const deltaX = x - startX;
const deltaY = y - startY;

modelContainer.rotation.y += deltaX * rotateSpeed;
modelContainer.rotation.x += deltaY * rotateSpeed;

// const theta = (x - startX) / radius * (-rotateSpeed)
// const phi = (y - startY) / radius * (-rotateSpeed)
// const maxRotationAngle = Math.PI / 2;
// // 没有变化那么停止
// if (Math.abs(theta) < .01 && Math.abs(phi) < .01) {
//     return
// }
// // 移动位置判断
// if (Math.abs(x - startX) >= Math.abs(y - startY)) {
//     // 旋转角度
//     model.rotation.y -= theta
// } else {
//     model.rotation.x -= phi
//     model.rotation.x = Math.max(-maxRotationAngle, Math.min(maxRotationAngle, model.rotation.x))
//     // this.gltfItemLights.rotation.x -= phi
//     // this.gltfItemLights.rotation.x = Math.max(-maxRotationAngle, Math.min(maxRotationAngle, model.rotation.x));
// }
mouseInfo.value.startX = x
mouseInfo.value.startY = y


// console.log("mouseInfo.value.startY", y)
}
// 模型缩放
const handleZoomOrPan = (event) => {
// 当自动旋转的时候 短暂停止
if (autoRotate.value && !preventStop.value) {
    clearTimer();
    // 防抖
    preventStop.value = true
    setTimeout(() => {
        setTimer();
        preventStop.value = false
    }, 2000);
}
let {touchStartX1,touchStartY1, touchStartX2, touchStartY2} = lastPoint.value;
let initialScale = modelContainer.scale.x;
const touch0 = event.touches[0]
const touch1 = event.touches[1]
const dx = (touch0.pageX - touch1.pageX)
const dy = (touch0.pageY - touch1.pageY)
const distance = Math.sqrt(dx * dx + dy * dy)
let obj = {
  touchStartX1: touch0.pageX,
  touchStartY1: touch0.pageY,
  touchStartX2: touch1.pageX,
  touchStartY2: touch1.pageY,
}
if (twoFirst.value) {
    twoFirst.value = false;
    lastPoint.value = {...obj}
} else {
    let deltaScale = initialScale * (distance / mouseInfo.value.startPointerDistance);
    // 限制缩放距离
    if (deltaScale < 0.25) {
      deltaScale = 0.25;
    } else if (deltaScale > 2) {
        deltaScale = 2
    }
    mouseInfo.value.startPointerDistance = distance;
    modelContainer.scale.set(deltaScale, deltaScale, deltaScale);

    const avgX = (touch0.pageX + touch1.pageX) / 2;
    const avgY = (touch0.pageY + touch1.pageY) / 2;
    const deltaX = avgX - (touchStartX1 + touchStartX2) / 2;
    const deltaY = avgY - (touchStartY1 + touchStartY2) / 2;

    modelContainer.position.x += deltaX * 0.3;
    modelContainer.position.y -= deltaY * 0.3; 
    
    lastPoint.value = {
      touchStartX1: touch0.pageX,
      touchStartY1: touch0.pageY,
      touchStartX2: touch1.pageX,
      touchStartY2: touch1.pageY,
    }
}
}

const handleTouchEnd = () => {
mouseInfo.value.isDown = false
mouseInfo.value.state = STATE.NONE
twoFirst.value = true;
lastPoint.value.touchStartX1 = 0;
lastPoint.value.touchStartY1 = 0;
lastPoint.value.touchStartX2 = 0;
lastPoint.value.touchStartY2 = 0;
// 取消移动事件监听
renderer.domElement.removeEventListener("touchmove",handleTouchMove, false)
renderer.domElement.removeEventListener("touchstart",handleTouchStart, false)
}

renderer.domElement.addEventListener('touchend', (event) => {
handleTouchEnd()
}); 

// 渲染场景
const animate = () => {
// controls.update();

requestAnimationFrame(animate);
renderer.render(scene, camera);
};

animate();

// 监听窗口缩放
window.addEventListener("resize", () => {
  renderer.setSize( window.innerWidth, window.innerHeight );

  camera.aspect = window.innerWidth / window.innerHeight;

  camera.updateProjectionMatrix();
});


}
//-------------------------------事件-------------------------------
// 设置背景颜色
const changeColorChild = (type, index, param) => {
scene.background = new THREE.Color(param);
emit("changeBackgound", index)
}
// 设置自定义背景
const changeTransparent = (type, index, param) => {
scene.background = null;
emit("changeBackgound", index)
}
// 按钮事件
const controlEvent = (val) => {

let data = localStorage.get("positionData");
let foundData = false;
data.filter(item=>{
  if (item.pid == props.pid && props.pid > 0 && !foundData) {
    foundData = true;
    if(val == 1) {
      ResetPosition();
    }else if(val == 2) {
      // 保存当前位置
      item.rotation.x = modelContainer.rotation.x
      item.rotation.y = modelContainer.rotation.y
      item.rotation.z = modelContainer.rotation.z
      item.scale.x = modelContainer.scale.x
      item.scale.y = modelContainer.scale.y
      item.scale.z = modelContainer.scale.z
      item.position.x = modelContainer.position.x
      item.position.y = modelContainer.position.y
      item.position.z = modelContainer.position.z
      // item.lightPosition.x = this.gltfItemLights.position.x
      // item.lightPosition.y = this.gltfItemLights.position.y
      // item.lightPosition.z = this.gltfItemLights.position.z
      // item.lightRotate.x = this.gltfItemLights.rotation.x
      // item.lightRotate.y = this.gltfItemLights.rotation.y
      // item.lightRotate.z = this.gltfItemLights.rotation.z
      // item.lightScale = this.data.lightScale;
      // item.lightScalePoint = this.data.lightScalePoint;
      localStorage.set("positionData",data )
      showSuccessToast("保存成功".$t)
    }else if(val == 3){
      spin();
    }
  }
})

}
// 重置位置
const ResetPosition = () => {
modelContainer.position.z = 0;
modelContainer.position.y = 0;
modelContainer.position.x = 0;
modelContainer.scale.z = 1;
modelContainer.scale.y = 1;
modelContainer.scale.x = 1;
modelContainer.rotation.z = 0;
modelContainer.rotation.y = 0;
modelContainer.rotation.x = 0;
}

// 自动旋转
const spin = () => {
if (!autoRotate.value) {
  if (preventStop.value) {
      return
  }
    setTimer()
  } else {
    clearTimer()
  }
}

// 关闭自动旋转
const clearTimer = () => {
  clearInterval(rotateTimer.value)
  rotateTimer.value = null;
  autoRotate.value = false;
  emit("rotateControl", false)
}
// 开启自动旋转
const setTimer = () => {
  clearInterval(rotateTimer.value)
  // 防抖
  rotateTimer.value = setInterval(() => {
      modelContainer.rotation.y -= 0.1
  }, 100)
  autoRotate.value = true;
  emit("rotateControl", true)
}

defineExpose({
changeColorChild,
changeTransparent,
ResetPosition,
controlEvent,
spin,
})

</script>
<style>
  body {
      margin: 0;
      overflow: hidden;
  }
  .control_bar {
    position: absolute;
  }
</style>