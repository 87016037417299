<template>
  <div>
    <div class="page">
      <div class="page_wrap">
        <van-search
          v-model="keyWord"
          background="#F3F3F3"
          shape="round"
          clearable
          @search="onSearch"
          @clear="getOrderList"
          input-align="center"
          placeholder="请输入订单号搜索"
        />
            <van-list
              v-model:loading="loading" v-model:error="error" :error-text="'请求失败，点击重新加载'" :finished-text="'没有更多了'"
              :finished="finished" @load="onLoad"
            >
            <div class="content">
              <div class="content_wrap">
                <div class="order_item" v-for="item, index in orderList" :key="index">
                  <div class="order_img">
                    <img :src="imgSrc(item.texture_cover_img)" >
                  </div>
                  <div class="order_info">
                    <div class="order_id">{{ item.pid }}</div>
                    <div class="order_size" >
                      <span v-for="el , elIndex in filterSize(item.model_size)" :key="elIndex">
                        {{ el }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as api from '@/api/multiPrint'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue';
const route = useRoute();
import {imgSrc} from "@/utils/utils"
const id = route.query.id as string | number;
const keyWord = ref('') 
const loading = ref(false)
const error = ref(false)
const finished = ref(false)
const refreshing = ref(false)
let currentPage = ref(1)
let totalCount = ref(0)
const orderList = ref([])
// const test = ref('18cm_x2___15cm_x3___20cm_x1')

onMounted(() => {
  
})

const onLoad = () => {
  if (refreshing.value) {
    currentPage.value = 1;
    orderList.value = [];
    refreshing.value = false;
  }

  getOrderList(currentPage.value)
  loading.value = false;

  if (orderList.value.length >= totalCount.value) {
    finished.value = true;
  }
};

const filterSize = (input: any) => {
  const items = input.split("___");  

  const result = items.map(item => {  
    const match = item.match(/(\d+)cm_x(\d+)/);  
    if (match) {  
      return `${match[1]}cm*${match[2]}`;  
    } else {  
      return item;  
    }  
  });  
  return result;  
}


const getOrderList = (page: any) => {
  api.detailPage({print_batch_id: id, keyWord: keyWord.value, page: page || 1}).then(res => {
    console.log(res)
    orderList.value = [...orderList.value, ...res.list]
    totalCount.value = res.total
    loading.value = false
  }).catch(err => {
    console.log(err)
    loading.value = false
  })
}

const onSearch = () => {
  currentPage.value = 1
  getOrderList(currentPage.value)
}

</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;  
  padding-top: 10px;
  .page_wrap {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0px auto;
    .content {
      position: relative;
      width: 100%;
      height: 93vh;
      overflow: scroll;
      margin: 10px 0;
      .content_wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .order_item {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 48%;
          min-height: 154px;
          background-color: #fff;
          border-radius: 10px;
          margin-bottom: 10px;
          overflow: hidden;
          .order_img {
            position: relative;
            width: 163px;
            height: 122px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .order_info {
            display: flex;
            position: relative;
            width: 90%;
            margin: 0 auto;
            font-size: 12px;
            font-weight: bold;
            color: #333;
            padding-top: 10px;
            .order_id {
              width: 100%;
            }
            .order_size {
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
              width: 100%;
              
            }
          }
        }
      }
    }
  }
}
:deep(.van-search) {
  padding: 0;
  background: #ffffff!important;
  border-radius: 30px;
}
:deep(.van-search__content) {
  background: #ffffff!important;
}
</style>